/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Row, Col } from "reactstrap";

export default function SingleItem({ publication }) {
  return (
    <Row>
      <Col md={4}>
        <img
          src={publication.thumbnail !== null ? publication.thumbnail : "http://pvr.mk/imgs/176-LinkMK.jpg.webp"}
          className="img-fluid"
        />
      </Col>
      <Col md={6} id="single-article" className="offset-md-1">
        <h3>{publication.category.name}</h3>
        <h2 className="sugg-title">{publication.name}</h2>
        <p className="author">{publication.author}</p>
        <p className="publisher">
          {new Date(publication.release_date).getFullYear()} <br />{" "}
          {publication.publisher}{" "}
        </p>
        <div>
          <p>
            Линкови:
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="text-dark small"
              href={publication.link_mk}
            >
              {" "}
              MK,{" "}
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="text-dark small"
              href={publication.link_en}
            >
              {" "}
              EN,{" "}
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="text-dark small"
              href={publication.link_sq}
            >
              {" "}
              SQ,{" "}
            </a>
          </p>
        </div>
        <div className="d-flex">
          {publication.tags.map((tag, index) => (
            <div className="tags p-2" key={index}>
              <p className="m-0" key={index}>
                {tag.name}
              </p>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}
