import React from 'react'
import { Container } from 'reactstrap'

export default function ContactTemplate(props) {
  return (
    <>
      <Container>
        <div className='pt-5 pb-5'>
          <h2>
            Контакт
          </h2>
          <p>
            email: <a href='mailto:podatoci@idscs.org.mk'> podatoci@idscs.org.mk</a>
          </p>
        </div>
      </Container>
    </>
  )
}
