import React from "react";
import endpoints from "../endpoints";

export default function usePublicationsHook() {
  const [publications, setPublications] = React.useState(null);

  const getPublications = (page = 0) => {
    fetch(
      `${process.env.REACT_APP_API_URL}${endpoints.getPublications}?limit=4&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => setPublications(data));
  };

  const getAllPublications = (page = 0) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}${endpoints.getPublications}?limit=4&page=${page}`
    )
      .then((res) => res.json())
  };

  return {
    publications,
    getPublications,
    getAllPublications
  };
}
