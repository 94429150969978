import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import useSinglePublicationHook from "../../hooks/useSinglePublicationHook";
import { PublicationTemplate } from "./../templates/_index";

export default function PublicationPage(props) {
  const { slug } = useParams();
  const { publication, similarPublication } = useSinglePublicationHook(slug);
  
  return (
    <Suspense fallback="Loading...">
      <PublicationTemplate publication={publication} similarPublication={similarPublication} />
    </Suspense>
  );
}
