import endpoints from "../endpoints";

export default function useSearchPublicationByTermHook() {
  const getPublications = (searchTerm = "null", rest = "") => {
    return fetch(
      `${process.env.REACT_APP_API_URL}${endpoints.searchTerm.replace(
        ":searchTerm",
        `${searchTerm}`
      )}?limit=4&page=1${rest}`
    ).then((res) => res.json());
  };

  const publicationsAsOptions = (arr) =>
    arr.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  return {
    getPublications,
    publicationsAsOptions,
  };
}
