import { Routes, Route } from "react-router-dom";
import React from 'react'
import { routeMapper } from "./";
import { CustomNavbar, Footer } from "./../layout/_index";

export default function Router(props) {
  return (
    <>
      <Routes>
        {routeMapper.map((item, index) => {
          return (
            <Route path={item.routeName} key={index} element={
              <div className="px-3">
                <CustomNavbar />
                {item.page}
                <Footer />
              </div>
            } />
          )
        })}
      </Routes>
    </>
  )
}