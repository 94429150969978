/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import LinkMK from "./../../assets/img/publications/132-LinkMK.jpg.webp";

export default function RelatedPublication({ item }) {
  return (
    <Row>
      <Col md={4} sm={12}>
        <img
          alt="case-study-thumbnail"
          className="img-fluid d-block mx-auto"
          src={item.thumbnail !== null ? item.thumbnail : LinkMK}
        />
      </Col>
      <Col md={8} sm={12} id="single-article">
        <>
          <h3>{item.category.name}</h3>
          <Link to={`publication/${item.id}/${item.slug}`} className="sugg-title">
          {item.name}
          </Link>
          <p className="author">{item.authors}</p>
          <p className="publisher">
          {new Date(item.release_date).getFullYear()} <br /> {item.publisher}{" "}
          </p>
          <p>
          {item.abstract.substring(0, 180)}
            <Link
              to={`publication/${item.id}/${item.slug}`}
              rel="noopener noreferrer"
              target="_blank"
              className="title text-decoration-none"
            >
              ...Повеќе
            </Link>
          </p>
        </>
      </Col>
    </Row>
  );
}
