import React from 'react'
import { Container } from 'reactstrap'

export default function HelpTemplate() {
  return (
    <Container className='pb-3'>
      <div className='pt-5 pb-5'>
        <p>
          Базата со податоци е организирана според претходно определени тематски области и клучни зборови. За секоја од публикациите наведени се 17 типови на информации: автори, година на издавање, издавач, краток опис на публикацијата (абстракт), овластен предлагач, повеќе типови на анализирани влијанија според образецот за ПВР, 3 кратки наоди, 3 кратки препораки, како и линк до оригиналното место на издавање на публикацијата.
        </p>
        <p>
          Пребарувањето низ податоците е можно преку текст, клучни зборови, тематски области, овластени предлагачи како и според анализирани влијанија (влијанија за кои се пишува во публикацијата).
        </p>
        <p>
          На пример, доколку се работи за социјалните влијанија во одреден ПВР извештај, во тој случај во делот за пребарување во полето Избери категорија, избирате социјални влијанија. Исто така, можно е да изберете и дополнителни филтри како економски влијанија со што ќе се појават само публикациите кои се означени дека обработуваат прашања поврзани со тие влијанија.

        </p>
        <p>
          За да разгледате одредена публикација, кликнувате на истата (се отвора во ново јазиче) со што се појуваат следните информации:
        </p>
        <ul>
          <li>
            Апстракт
          </li>
          <li>
            Наоди (три сумирани наоди)
          </li>
          <li>
            Препораки (три сумирани препораки)
          </li>
          <li>
            Клучни зборови
          </li>

        </ul>
        <p>
          На секоја од публикациите достапен е и линк кон веб-страницата на издавачот каде што е достапна целосната публикација.  За повеќе информации и асистенција за користењето на базата на податоци, корекции, забелешки, контактирајте го Марко Панковски (Постар истражувач, Институт за демократија „Социетас Цивилис“ - Скопје, mail: pankovski@idscs.org.mk) или алтернативно на podatoci@idscs.org.mk.
        </p>
      </div>
    </Container>
  )
}
