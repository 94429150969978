import {
    AboutPage,
    ContactPage,
    HelpPage,
    HomePage,
    ResourcesPage,
    PublicationPage
} from '../views/pages/_index'
    
export const routeMapper = [
    {
        routeName: '/about',
        page: <AboutPage />,
    },
    {
        routeName: '/',
        page: <HomePage />,
    },
    {
        routeName: '/contact',
        page: <ContactPage />,
    },
    {
        routeName: '/help',
        page: <HelpPage />,
    },
    {
        routeName: '/resources',
        page: <ResourcesPage />,
    },
    {
        routeName: '/publication/:id/:slug',
        page: <PublicationPage />,
    }
]