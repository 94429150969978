/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Navbar,
  NavbarBrand,
  Collapse,
  NavItem,
  NavbarToggler,
  NavbarText,
  Nav,
} from "reactstrap";
import { ImFacebook, ImTwitter } from "react-icons/im";
import { Link } from "react-router-dom";
import logo from "./../assets/img/navbar/logo.png";

export default function CustomNavbar() {
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="px-4 pt-2 pb-5" id="navbar">
      <Navbar color="white" expand="md" className="d-flex" light>
        <NavbarBrand href="/">
          <img src={logo} height={"105px"} width={"auto"} alt={"prv.mk"} />
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Collapse isOpen={isOpen} navbar className="text-center">
          <Nav className="me-auto" navbar>
            <NavItem>
              <>
                <Link to="/" className="nav-link">
                  Почетна
                </Link>
              </>
            </NavItem>
            <NavItem>
              <>
                <Link to="/about" className="nav-link">
                  За нас
                </Link>
              </>
            </NavItem>
            <NavItem>
              <>
                <Link to="/help" className="nav-link">
                  Помош
                </Link>
              </>
            </NavItem>
            <NavItem>
              <>
                <Link to="/resources" className="nav-link">
                  Ресурси
                </Link>
              </>
            </NavItem>
            <NavItem>
              <>
                <Link to="/contact" className="nav-link">
                  Контакт
                </Link>
              </>
            </NavItem>
          </Nav>
          <NavbarText>
            <div className="d-sm-flex d-flex justify-content-center">
              <div className="px-1 ms-4 ms-s-4 ms-md-0 ms-lg-0">
                <a
                  href="https://www.facebook.com/IDSCS/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <ImFacebook
                    size={25}
                    color={"#094C8C"}
                    className="cursor-pointer"
                  />
                </a>
              </div>
              <div className="px-1">
                <a
                  href="https://twitter.com/IDSCS_Skopje"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <ImTwitter
                    size={25}
                    color={"#094C8C"}
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
}
