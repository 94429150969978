import React from 'react'
import { Container } from 'reactstrap'

export default function AboutTemplate() {
  return (
    <Container className='pb-5'>
      <div className='pt-5 pb-5'>
        <h2>
          За нас
        </h2>
        <p>
          Добредојдовте на ресурсниот центар за проценка на влијание на регулатива,
        </p>
        <p>
          Ресурниот центар претставува интерактивна веб-платформа која интегрира сумирани податоци за речиси 800 издадени публикации од граѓански организации со цел обезбедување на истражувања кои би биле од корист на службениците кои работат на формуларите за проценка на влијание на регулатива, но и пошироко, на креаторите на политиките, донесувачите на одлуки и граѓанските организации кои имаат интерес во процесот на создавање политики базирани на докази.
        </p>
        <p>
          Главна компонента на ресурсниот центар е базата со публикации која е организирана според претходно определени тематски области и клучни зборови. За секоја од публикациите наведени се 17 категории на информации како: автори, година на издавање, издавач, краток опис на публикацијата (апстракт), област, овластен предлагач, повеќе типови на анализирани влијанија според образецот за ПВР, 3 кратки наоди, 3 кратки препораки, кон кого се насочени истите, како и линк до веб-страницата каде што е прикачена публикацијата. Примарната цел на ваквата структура е да им овозможи на вработените во министерствата да ја зголемат употребата на истражувања од граѓанските организации во ПВР извештаите.
        </p>
        <p>
          Ресурсниот центар е континуирано надополнуван од страна на Институт за демократија „Социетас цивилис“ – Скопје (ИДСЦС), преку проектите „Негување на јавната дебата за отчетност“ и “ Поттикнување на јавната дебата за антикорупција“, поддржани од Националниот фонд за демократија (National Endowment for Democracy - NED).
        </p>
        <p>
          Првичната верзија на ресурсниот центар е изработена од од Институтот за демократија „Социетас Цивилис“ - Скопје и Центарот за економски анализи (ЦЕА) во рамки на проектот „Проценка на влијанието на регулативата во сенка: Промовирање на креирање политики базирани на докази” финансиран од Делегацијата на Европската Унија во Скопје.
        </p>
        <p>
          За повеќе информации и асистенција околу користењето на базата на податоци, корекции или забелешки, ве молиме контактирајте нé на <a href="mailto:podatoci@idscs.org.mk">podatoci@idscs.org.mk</a>. ------
        </p>
      </div>
    </Container>
  )
}