import endpoints from "../endpoints";

export default function useCategoriesHook() {
  const getCategories = () => {
    return fetch(
      `${process.env.REACT_APP_API_URL}${endpoints.getCategories}`
    ).then((res) => res.json());
  };

  const categoriesAsOptions = (arr) =>
    arr.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  return {
    getCategories,
    categoriesAsOptions,
  };
}
