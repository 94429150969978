/* eslint-disable no-undef */
import Router from './router/Router'

function App(props) {
  console.log(process.env);
  return (
    <>
      <Router {...props} />
    </>
  );
}

export default App;
