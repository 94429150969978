import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import LinkMK from "./../../assets/img/publications/132-LinkMK.jpg.webp";

export default function PublicationItem({ item }) {
  return (
    <>
      <Row className="pb-5 mb-5 p-1 pb-2">
        <Col md={5} sm={4}>
          <Link
            to={`publication/${item.id}/${item.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={item.thumbnail !== null ? item.thumbnail : LinkMK} className="img-thumbnailn img-fluid" alt="..." />
          </Link>

          <div className="d-none d-sm-none d-md-block d-lg-block">
            {item.tags.map((tag, index) => <p className="tags" key={index}>{tag.name}</p>)}
          </div>
          <Link
            to={`publication/${item.id}/${item.slug}`}
            target="_blank"
            rel="noopener noreferrer"
            className="d-block p-2 p-sm-0 p-md-0 p-lg-0 nav-link"
          >
            Линк
          </Link>
        </Col>
        <Col md={7} sm={8}>
          <div className="caseCategory mb-3">
            <p className="d-block d-sm-block d-md-inline d-lg-inline">
            {item.category.name}
            </p>
          </div>
          <Link
            className="caseTitle"
            to={`publication/${item.id}/${item.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
           {item.name}
          </Link>
          <strong className="caseAuthors d-block">
            {item.authors}
          </strong>
          <small className="publishDate py-3 d-block">
            {new Date(item.release_date).getFullYear()}
            <br />
            {item.publisher}
          </small>
          <div className="caseAbstract d-none d-sm-none d-md-block d-lg-block">
            {item.abstract.substring(0, 180)}
            <Link
              to={`publication/${item.id}/${item.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              ... Повеќе
            </Link>
          </div>
        </Col>
        <Col md={12}>
          <div className="caseAbstract d-block d-sm-block d-md-none d-lg-none">
          {item.abstract.substring(0, 180)}
            <Link
              to={`publication/${item.id}/${item.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              ... Повеќе
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
}
