/* eslint-disable jsx-a11y/alt-text */
import React, { Suspense } from "react";
import { Row, Col } from "reactstrap";
import AccordionSingleItem from "../organizams/AccordionSingleItem";
import RelatedPublication from "../organizams/RelatedPublication";
import SingleItem from "../organizams/SingleItem";
import SuggestedPublication from "../organizams/SuggestedPublication";

export default function PublicationTemplate({
  publication,
  similarPublication,
}) {
  if (publication === null || similarPublication === null) {
    return (
      <Row>
        <Col md={12}>
          <h5 className="pb-5 px-5 mx-5">Се вчитува ...</h5>
        </Col>
      </Row>
    );
  }

  return (
    <div className="pb-5 px-5">
      <Row>
        <Col md={9}>
          <SingleItem publication={publication} />
          <AccordionSingleItem publication={publication} />
        </Col>
        <Col md={3}>
          <Row>
            <Col
              md={12}
              id="related-publications"
              className="pb-2 mb-1 text-center"
            >
              ПРЕПОРАЧАНИ ПУБЛИКАЦИИ <br />{" "}
            </Col>
            {similarPublication.slice(0, 2).map((item, index) => {
              return (
                <Col md={12} key={index}>
                  <SuggestedPublication item={item} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} id="related-publications" className="pb-5 mb-5">
          СЛИЧНИ ПУБЛИКАЦИИ <br />{" "}
        </Col>
        <Suspense fallback="">
          {similarPublication.slice(2, 4).map((item, index) => {
            return (
              <Col md={6} key={index}>
                <RelatedPublication item={item} />
              </Col>
            );
          })}
        </Suspense>
      </Row>
    </div>
  );
}
