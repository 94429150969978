import React from 'react'
import { Container } from 'reactstrap'

export default function ResourcesTemplate() {
  return (
    <Container className='pb-5'>
      <div className='pt-5 pb-5'>
        <h2>
          Корисни Линкови
        </h2>
        <ul>
          <li>
            Единствен национален регистар на прописи (ЕНЕР) - <a href="http://pvr.mk/">www.ener.gov.mk</a>
          </li>
          <li>
            Акти кои ја уредуваат материјата за проценка на влијаније на регулатива - <a href="http://www.mioa.gov.mk/sites/default/files/pbl_files/documents/Akti_PVR_1mk.pdf">http://www.mioa.gov.mk/sites/default/files/pbl_files/documents/Akti_PVR_1mk.pdf</a>
          </li>
          <li>
            Прирачник за проценка на влијание на регулатива - <a href="http://arhiva.mioa.gov.mk/files/pdf/Priracnik%20za%20PVR2_mk.pdf">http://arhiva.mioa.gov.mk/files/pdf/Priracnik%20za%20PVR2_mk.pdf</a>
          </li>
          <li>

            Прирачник за засегнати страни - Консултациите во процесот на креирање на политики во Владата на Република Македонија - <a href="http://arhiva.mioa.gov.mk/files/pdf/Priracnik%20za%20zasegnati%20strani_3.pdf">http://arhiva.mioa.gov.mk/files/pdf/Priracnik%20za%20zasegnati%20strani_3.pdf</a>

          </li>
        </ul>
      </div>
    </Container>

  )
}
