/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import LinkMK from "./../../assets/img/publications/132-LinkMK.jpg.webp";

export default function SuggestedPublication({ item }) {
  return (
    <Row>
      <Col md={12} id={`single-article-${item.id}`} className="text-center pb-4">
        <>
          <p className="author">{item.authors}</p>
          <img
            alt="case-study-thumbnail"
            className="img-fluid mx-auto d-block w-50 mb-2"
            src={item.thumbnail !== null ? item.thumbnail : LinkMK}
          />
          <Link to={`publication/${item.id}/${item.slug}`} className="sugg-title ">
          {item.name}
          </Link>
        </>
      </Col>
    </Row>
  );
}
