/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import endpoints from "../endpoints";

export default function useSinglePublicationHook(slug) {
  const [publication, setPublication] = React.useState(null);
  const [similarPublication, setSimilarPublication] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}${endpoints.singlePublication.replace(
        ":slug",
        slug
      )}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPublication(data.publication)
        setSimilarPublication(data.similar_publications)
      });
  }, []);
  
  return {
    publication,
    similarPublication
  };
}
