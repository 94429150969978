import React, { useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
export default function AccordionSingleItem({ publication }) {
  const [activeTab, setActiveTab] = useState(1);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    document.getElementById("tab-1").classList.remove("active");
    document.getElementById("tab-2").classList.remove("active");
    document.getElementById("tab-3").classList.remove("active");
    document.getElementById("tab-4").classList.remove("active");

    document.getElementById(`tab-${tab}`).classList.add("active");
  };

  return (
    <Row className="mt-5 pt-2" id="single-item-accordion">
      <Col md={12}>
        <>
          <Nav>
            <NavItem>
              <NavLink
                className="pe-3 ps-0 active"
                id="tab-1"
                onClick={() => handleActiveTab(1)}
              >
                Апстракт
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="pe-3"
                id="tab-2"
                onClick={() => handleActiveTab(2)}
              >
                Наоди
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="pe-3"
                id="tab-3"
                onClick={() => handleActiveTab(3)}
              >
                Препораки
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="pe-3"
                id="tab-4"
                onClick={() => handleActiveTab(4)}
              >
                Клучни зборови
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab.toString()} className="mt-4">
            <TabPane tabId="1">
              <p>
                {publication.abstract}
              </p>
            </TabPane>
            <TabPane tabId="2">
              <p>
              {publication.key_findings}
              </p>
            </TabPane>
            <TabPane tabId="3">
              <p>
              {publication.referrals}
              </p>
            </TabPane>
            <TabPane tabId="4">
            {publication.keywords}
            </TabPane>
          </TabContent>
        </>
      </Col>
    </Row>
  );
}
