import React from 'react'
import { Row, Col } from 'reactstrap'

import eu from './../assets/img/footer/eu.png';
import euzatebe from './../assets/img/footer/euzatebe.png';
import idscs from './../assets/img/footer/idscs.png';
import mkd from './../assets/img/footer/mkd.png';
import nedlogo from './../assets/img/footer/nedlogo.png';

export default function Footer() {
  return (
    <>
      <div className='py-4 pt-4 pb-4 footer-bg'>
        <div className='d-flex flex-sm-column flex-md-row flex-lg-row flex-column text-center justify-content-around mb-5 mt-5'>
          <div className='p-1'>
            <img src={idscs} alt='eu' />
          </div>
          <div className='p-1'>
            <img src={mkd} alt='eu' />
          </div>
          <div className='p-1'>
            <img width={300} src={nedlogo} alt='eu' />
          </div>
        </div>
        <Row className='pb-5'>
          <Col className='text-center pb-5 mx-3'>
            Овој производ е изготвен со помош на Националната фондација за демократија на САД (National Endowment for Democracy - NED). Содржината на веб страницата е единствена одговорност на Институт за демократија „Социетас Цивилис“ – Скопје и не нужно ги одразува гледиштата на Националната фондација за демократија од САД.
          </Col>
        </Row>
      </div>

    </>
  )
}
