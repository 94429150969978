/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import { FilterOrganizam, PublicationItem } from "./../organizams/_Index";
import InfiniteScroll from "react-infinite-scroller";

export default function HomeTemplate({
  publicationsDS,
  getPublications,
}) {
  const [publications, setPublications] = useState([]);
  const [loadMoreItems, setLoadMoreItems] = useState(true);

  useEffect(() => {
    if (publicationsDS === null) return;
    setPublications([...publications, ...publicationsDS.data]);
  }, [publicationsDS]);

  return (
    <div>
      <div className="searchContainer filterImage pb-0">
        <div className="text-center">
          <FilterOrganizam setPublications={setPublications} setLoadMoreItems={setLoadMoreItems} />
        </div>
        <div className="container">
          <h3 className="pt-5">СЛУЧАЈНО ИЗБРАНИ ПУБЛИКАЦИИ</h3>
        </div>
      </div>
      <div className="container pt-0 pt-sm-2 pt-md-5 pt-lg-5">
        <div className="pt-sm-2 pt-5">
          <Suspense fallback="Not available publications...">
            <h4 id="no-publications-afterfilter" className="text-center d-none">Нема резултати со таков филтер за публикации...</h4>
            <InfiniteScroll
              id="no-publications-afterfilter-scroll"
              pageStart={0}
              loadMore={(page) => {
                page !== 1 &&
                  page < publicationsDS.links.length - 1 &&
                  getPublications(page);

                page !== 1 &&
                  page === publicationsDS.links.length - 1 &&
                  setLoadMoreItems(false);
              }}
              hasMore={loadMoreItems}
              loader={
                <h5 className="loader" id="no-publications-afterfilter-loader" key={0}>
                  ...
                </h5>
              }
              className="row"
            >
              {publications.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="col-12 col-sm-6 col-md-6 col-lg-6"
                      key={index}
                    >
                      <PublicationItem item={item} />
                    </div>
                    {(index + 1) % 2 === 0 && <hr className="mb-5" />}
                  </React.Fragment>
                );
              })}
            </InfiniteScroll>
          </Suspense>
        </div>
      </div>
    </div>
  );
}
