/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import usePublicationsHook from "../../hooks/usePublicationsHook";

import { HomeTemplate } from "./../templates/_index";

export default function HomePage(props) {
  const { publications, getPublications } = usePublicationsHook();

  useEffect(() => {
    getPublications(1);
  }, []);

  return (
    <HomeTemplate
      {...props}
      publicationsDS={publications}
      getPublications={getPublications}
    />
  );
}
