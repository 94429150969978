/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import useCategoriesHook from "./../../hooks/useCategoriesHook";
import AsyncSelect from "react-select/async";
import useSearchPublicationByTermHook from "../../hooks/useSearchPublicationByTermHook";
import Select from "react-select";
import usePublicationsHook from "../../hooks/usePublicationsHook";

export default function FilterOrganizam({ setPublications, setLoadMoreItems }) {
  const { getCategories, categoriesAsOptions } = useCategoriesHook();
  const { getPublications, publicationsAsOptions } =
    useSearchPublicationByTermHook();

  const [categoryOptions, setCategoryOptions] = useState(null);
  const [selectedPublication, setSelectedPublication] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    getCategories().then((res) => {
      setCategoryOptions(categoriesAsOptions(res));
    });
  }, []);

  const loadOptions = (selected) => {
    return getPublications(selected).then((res) =>
      publicationsAsOptions(res.data)
    );
  };
  
  const { getAllPublications } = usePublicationsHook();

  const handleNewPublicationList = (e) => {
    e.preventDefault();

    setLoadMoreItems(false);
    setPublications([]);
    document
      .getElementById("no-publications-afterfilter")
      .classList.add("d-none");
    document
      .getElementById("no-publications-afterfilter-scroll")
      .classList.remove("d-none");

    const filterByCategory =
      selectedCategory !== null
        ? `&filter[category_id]=${selectedCategory.value}`
        : "";

    getPublications(selectedPublication === null ? 'а' : selectedPublication.label, filterByCategory).then((res) => {
      console.log(res);
      setPublications(res.data);
      setLoadMoreItems(res.data.length !== 0);
      if (res.data.length === 0) {
        document
          .getElementById("no-publications-afterfilter")
          .classList.remove("d-none");
        document
          .getElementById("no-publications-afterfilter-scroll")
          .classList.add("d-none");
      }
    });
  };

  return (
    <form id="searchForm" method="GET" onSubmit={handleNewPublicationList} className="container">
      <div className="row pt-2 pb-2 bg-white">
        <div className="form-group form-group-category col-md-3 col-sm-12">
          <div>
            <Select
              options={categoryOptions}
              placeholder="Kатегорија"
              id="input_category"
              name="filter-category"
              className="text-dark mt-2 mb-2"
              data-placeholder="All Categories"
              aria-hidden="true"
              onChange={(value) => setSelectedCategory(value)}
            />
          </div>
        </div>
        <div className="form-group form-group-category col-md-6 col-sm-12">
          <div className="input-group">
            <AsyncSelect
              placeholder="Пребарувај"
              className="mb-2 mt-2 w-100 text-st"
              id="select_category"
              loadOptions={loadOptions}
              isClearable
              isSearchable
              cacheOptions={true}
              onChange={(value) => setSelectedPublication(value)}
            />
          </div>
        </div>
        <div className="form-submit col-md-3 col-sm-12">
          <button
            id="searchBtn"
            type="submit"
            className="btn-submit btn btn-theme btn-block mb-2 mt-2 w-100 text-center"
          >
            <ImSearch size={20} className="m-1 mx-2" />
            <span className="btn-text">Пребарувај</span>
          </button>
        </div>
      </div>
    </form>
  );
}
